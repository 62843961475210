























































import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import { IItem } from '@/components/ui/types';
import { EnumsActions, EnumsIcons } from '@/store/modules/globals/types';
import RepositoryFactory from '@/services/RepositoryFactory';
import IBoxShippingsRepository from '@/services/Repositories/BoxShippings/types';
import IBoxesRepository from '@/services/Repositories/Boxes/types';

const factory = new RepositoryFactory();

export default Vue.extend({
  name: 'reboxId',
  components: {
    ItemCounter: () => import('@/components/ui/ItemCounter.vue'),
    ReboxDetails: () => import('@/components/ui/ReboxDetails.vue'),
    BasePopup: () => import('@/components/ui/BasePopup.vue'),
  },
  data() {
    return {
      returnSuccessPopupIsOpen: false,
      boxStatus: '',
      boxDamaged: [],
      customerDetails: {
        customer: '',
        customerId: '',
      },
      items: [],
    };
  },
  computed: {
    ...mapState('globals', ['boxStates']),
    atLeastOneStateCheckbox(): boolean {
      let atLeastOneBox = false;
      this.boxStates.forEach((state: any) => {
        if (state.stateIsTrue) {
          atLeastOneBox = true;
        }
      });
      return atLeastOneBox;
    },
    reboxId() {
      const {
        params: { reboxId },
      } = this.$route;
      return reboxId;
    },
    boxShippingsRepository(): IBoxShippingsRepository {
      return factory.get('boxShippings') as IBoxShippingsRepository;
    },
    boxesRepository(): IBoxesRepository {
      return factory.get('boxes') as IBoxesRepository;
    },
  },
  methods: {
    ...mapMutations('globals', ['SET_TITLE', 'SET_ICON', 'SET_ACTION', 'SET_PAGE', 'CLEAR_BOXSTATES']),
    ...mapMutations('lastEdited', ['ADD_EDITED_BOX']),
    async returnBox() {
      const { data } = await (this as any).boxesRepository.returnBox(this.$route.params.reboxId, {
        boxReturn: {
          notes: '',
          boxReturnInventoryItems: (this as any).items,
        },
      });
      const { status } = data;
      (this as any).boxStatus = status;
    },
    async changeBoxStatusToDeliveredToMerchant(): Promise<void> {
      // eslint-disable-next-line max-len
      await (this as any).boxesRepository.changeBoxStatusToDeliveredToMerchant((this as any).reboxId);
    },
    async getBoxReturn() {
      const { data } = await (this as any).boxesRepository.getOne(this.$route.params.reboxId);
      const { status } = data;
      (this as any).boxStatus = status;
      if (status) {
        const boxShipping = data.currentBoxShipping;
        const { data: shipping } = await (this as any).boxShippingsRepository.getOne(
          boxShipping.id,
        );
        const inventoryItems = [] as any;
        const { custName, custSurname, custNr } = shipping.order;
        shipping.boxShippingInventoryItems.forEach((item: any) => {
          const singleItem = {
            id: item.id,
            amount: parseInt(item.inventoryItemAmount, 10),
            title: item.inventoryItemName,
            maxAmount: item.inventoryItemAmount > 0 ? item.inventoryItemAmount : false,
          };
          inventoryItems.push(singleItem);
        });
        (this as any).items = inventoryItems;
        (this as any).customerDetails = {
          customer: `${custName} ${custSurname}`,
          customerId: custNr,
        };
      } else {
        (this as any).popupIsOpen = true;
      }
    },
    updateItem(item: IItem): void {
      const indexOfItem = (this as any).items.findIndex(({ id }: { id: number }) => id === item.id);
      if (item.amount >= 0 && !Number.isNaN(item.amount)) {
        Vue.set((this as any).items, indexOfItem, item);
      } else {
        const { id, title, amount } = item;
        Vue.set((this as any).items, indexOfItem, {
          title,
          id,
          amount,
        });
      }
    },
    confirm() {
      this.$router.push({
        name: 'ReboxNote',
        params: {
          reboxId: (this as any).reboxId,
          items: (this as any).items,
        },
      });
    },
    openPopup() {
      (this as any).returnSuccessPopupIsOpen = true;
    },
    async handleClick() {
      if ((this as any).boxStatus !== 'DELIVERED_TO_MERCHANT') {
        await (this as any).changeBoxStatusToDeliveredToMerchant();
      }
      this.sendBoxInformations();
    },
    sendBoxInformations() {
      if ((this as any).boxStates[1].stateIsTrue) {
        (this as any).confirm();
      } else {
        (this as any).returnBox();
        (this as any).openPopup();
        this.CLEAR_BOXSTATES();
      }
    },
    backToScanProccess() {
      (this as any).ADD_EDITED_BOX((this as any).reboxId);
      this.$router.replace({ name: 'ReboxScanner' });
      (this as any).popupIsOpen = false;
    },
  },
  created() {
    (this as any).SET_TITLE('header.reboxCheck');
    (this as any).SET_ICON(EnumsIcons.back);
    (this as any).SET_ACTION(EnumsActions.goBack);
    (this as any).SET_PAGE('ReboxScanner');
    (this as any).getBoxReturn();
    this.CLEAR_BOXSTATES();
  },
  watch: {
    items: {
      handler(val) {
        const amounts = Object.values(val)
          .map(({ amount }: any) => parseInt(amount, 10))
          .filter((amount) => amount > 0);
        if (amounts.length) {
          (this as any).SET_ICON(EnumsIcons.cancel);
          (this as any).SET_ACTION(EnumsActions.close);
        }
      },
      deep: true,
    },
    boxDamaged: {
      handler(val: string[]) {
        if (val.length) {
          (this as any).SET_ICON(EnumsIcons.cancel);
          (this as any).SET_ACTION(EnumsActions.close);
        }
      },
    },
  },
});
